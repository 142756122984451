.nfm-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 6005;
  overflow-x: hidden;
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  box-sizing: border-box;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }
  .nfm-container.ng-enter, .nfm-container.ng-leave {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
  .nfm-container.ng-enter, .nfm-container.ng-leave.ng-leave-active {
    opacity: 0; }
  .nfm-container.ng-leave, .nfm-container.ng-enter.ng-enter-active {
    opacity: 1; }
  .nfm-container > .nfm-close {
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    position: absolute;
    right: 20px;
    top: 10px;
    display: inline-block;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: #eee;
    cursor: pointer;
    z-index: 5;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
    font-style: normal; }
    .nfm-container > .nfm-close:before {
      content: "\00d7"; }
  .nfm-container > .nfm-modal {
    margin: auto;
    position: relative;
    z-index: 3;
    max-width: 100%;
    background-color: #fff;
    padding: 20px 30px; }
  @media screen and (max-width: 500px) {
    .nfm-container {
      background-color: #fff !important; }
      .nfm-container > .nfm-close {
        color: #333 !important; }
      .nfm-container > .nfm-modal {
        width: 100% !important; } }
